import {
  IconButton,
  InputBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useDialogs } from "@promaton/frontend-common";
import {
  CheckCircle,
  CircleFilledLarge,
  CrossCircle,
  Search,
  Trash,
} from "@promaton/icons";
import { FC, forwardRef, memo, useMemo } from "react";
import { useForm } from "react-hook-form";
import { TableVirtuoso } from "react-virtuoso";
import { Link } from "wouter";

import { APIOutput, trpc } from "../hooks/trpc";
import { createRouteLink, routes } from "../routes/routes";
import { formatDate } from "../utils/dateFormatter";
import { AddAssignments } from "./AddAssignments";
import { statusColor } from "./StatusChip";

type Assignment = APIOutput["assignment"]["list"][0];

export const AdminAssignmentTable: FC<{ id: string }> = ({ id }) => {
  const { register, watch } = useForm<{
    search: string;
  }>();
  const search = watch("search");

  const assignmentQuery = trpc.assignment.list.useInfiniteQuery(
    {
      project: id,
      includeSubmissionState: true,
      sort: "name",
      search: search || undefined,
      pageSize: 100,
    },
    {
      getNextPageParam: (lastPage) => lastPage.at(-1)?.id,
    }
  );

  const assignments = useMemo(() => {
    return assignmentQuery.data?.pages.flat();
  }, [assignmentQuery.data]);

  return (
    <>
      <Stack flexDirection={"row"} gap={2}>
        <InputBase
          startAdornment={<Search sx={{ marginRight: 1 }} />}
          type="search"
          placeholder="Search by slug"
          autoComplete="off"
          {...register("search", {
            minLength: 1,
          })}
          fullWidth
          sx={{
            flex: 1,
            padding: 1,
            paddingLeft: 2,
            background: (t) => t.palette.action.hover,
            borderRadius: (t) => t.spacing(1),
          }}
        />
        <AddAssignments projectId={id} />
      </Stack>
      <TableVirtuoso
        useWindowScroll
        endReached={() => {
          assignmentQuery.fetchNextPage();
        }}
        components={{
          Scroller: forwardRef((props, ref) => (
            <div {...props} ref={ref}></div>
          )),
          Table: (props) => (
            <Table
              size="small"
              {...props}
              style={{ borderCollapse: "separate" }}
            />
          ),
          TableHead: (props) => (
            <TableHead {...props} sx={{ top: "74px !important" }} />
          ),
          TableRow: (props) => (
            <TableRow
              {...props}
              sx={{
                ["& td"]: {
                  borderBottomColor: (t) => t.palette.divider,
                },
                ["&:not(:hover) td:last-child button"]: {
                  opacity: 0,
                },
              }}
            />
          ),
          TableBody: forwardRef((props, ref) => (
            <TableBody {...props} ref={ref} />
          )),
        }}
        style={{
          flex: "0 0 auto",
          height: 100,
          width: "100%",
        }}
        totalCount={assignments?.length}
        data={assignments}
        fixedHeaderContent={() => (
          <TableRow
            sx={{
              background: (t) => t.palette.background.default,

              ["& th"]: {
                borderBottomColor: (t) => t.palette.divider,
              },
            }}
          >
            <TableCell sx={{ paddingLeft: 0 }}>Slug</TableCell>
            <TableCell align="center">Submissions</TableCell>
            <TableCell align="center">Picked up</TableCell>
            <TableCell align="center" sx={{ maxWidth: 10 }}>
              Available
            </TableCell>
            <TableCell>Created</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        )}
        itemContent={(index, assignment) =>
          assignment ? (
            <AssignmentTableRow assignment={assignment} key={assignment.id} />
          ) : null
        }
      />
    </>
  );
};

export const AssignmentTableRow: FC<{ assignment: Assignment }> = memo(
  ({ assignment }) => {
    const submissions = assignment.submissions;
    const total = useMemo(() => {
      return Array.from(new Array(assignment.project.submissionsPerAssignment));
    }, [assignment]);
    const utils = trpc.useContext();
    const deleteAssignment = trpc.assignment.delete.useMutation();

    return (
      <>
        <TableCell sx={{ paddingLeft: 0, width: 300 }}>
          {assignment.sourceSlug}
        </TableCell>
        <TableCell align="center">
          {total.map((_, i) => {
            const submission = submissions[i];
            const link = submission
              ? createRouteLink(routes.assignment, {
                  slug: assignment.sourceSlug,
                  id: submission.id,
                })
              : undefined;
            return (
              <Link key={i} href={link || ""}>
                <Tooltip title={submission?.state ?? "Not started"}>
                  <IconButton
                    size="small"
                    sx={{ color: (t) => t.palette.divider, padding: 0.25 }}
                  >
                    <CircleFilledLarge
                      color={
                        submission ? statusColor[submission.state] : "inherit"
                      }
                    />
                  </IconButton>
                </Tooltip>
              </Link>
            );
          })}
        </TableCell>
        <TableCell align="center">
          {assignment._count.submissions} /{" "}
          {assignment.project.submissionsPerAssignment}
        </TableCell>
        <TableCell align="center">
          {assignment.isAvailable ? (
            <CheckCircle />
          ) : (
            <CrossCircle sx={{ opacity: 0.5 }} />
          )}
        </TableCell>
        <TableCell>{formatDate(assignment.createdAt)}</TableCell>
        <TableCell align="right" sx={{ maxWidth: 10 }}>
          <IconButton
            onClick={() => {
              useDialogs.getState().setConfirmation({
                title: `Are you sure you want to delete the assignment for ${assignment.sourceSlug}?`,
                description: `This will also delete any related submissions (${assignment._count.submissions})`,
                onConfirm: async () => {
                  await deleteAssignment.mutateAsync({ id: assignment.id });
                  utils.assignment.invalidate();
                  utils.submission.invalidate();
                  utils.project.invalidate();
                },
              });
            }}
          >
            <Trash />
          </IconButton>
        </TableCell>
      </>
    );
  }
);

import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDialogs } from "@promaton/frontend-common";
import { Grid, Search, Trash } from "@promaton/icons";
import { FC, forwardRef, memo, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { TableVirtuoso } from "react-virtuoso";
import { Link } from "wouter";

import { type APIOutput, trpc } from "../hooks/trpc";
import { Role, useRole } from "../hooks/useRole";
import { createRouteLink, routes } from "../routes/routes";
import { formatDate, formatTime } from "../utils/dateFormatter";
import { EmptyState } from "./EmptyState";
import { StatusChip } from "./StatusChip";

type Submission = APIOutput["submission"]["listAll"][number];
type State = Submission["state"];
type Review = Submission["reviews"][number];

export const AdminSubmissionTable: FC<{
  id?: string;
  states?: State[];
  includeArchived?: boolean;
}> = ({ id, states, includeArchived = true }) => {
  const { register, watch } = useForm<{
    search: string;
  }>();
  const search = watch("search");
  const submissionQuery = trpc.submission.listAll.useInfiniteQuery(
    {
      project: id,
      search: search || undefined,
      includeArchived,
      states,
    },
    {
      getNextPageParam: (lastPage) => lastPage.at(-1)?.id,
      cacheTime: 1,
    }
  );

  const submissions = useMemo(() => {
    return submissionQuery.data?.pages.flat();
  }, [submissionQuery.data]);

  const [selectedSubmission, setSelectedSubmission] =
    useState<Submission | null>(null);
  const toggleDialog = (submission: Submission | null) => {
    setSelectedSubmission(submission);
  };

  const getReviewState = (review: Review) => {
    if (review?.isDraft) {
      return "Draft";
    }
    if (review?.isRejected) {
      return "Rejected";
    }
    return "Reviewed";
  };

  return (
    <>
      <InputBase
        startAdornment={<Search sx={{ marginRight: 1 }} />}
        type="search"
        placeholder="Search by slug, annotator or reviewer..."
        autoComplete="off"
        {...register("search", {
          minLength: 1,
        })}
        fullWidth
        sx={{
          flex: 1,
          padding: 1,
          paddingLeft: 2,
          background: (t) => t.palette.action.hover,
          borderRadius: (t) => t.spacing(1),
        }}
      />

      <TableVirtuoso
        useWindowScroll
        endReached={() => {
          submissionQuery.fetchNextPage();
        }}
        components={{
          Scroller: forwardRef((props, ref) => (
            <div {...props} ref={ref}></div>
          )),
          Table: (props) => (
            <Table
              size="small"
              {...props}
              style={{ borderCollapse: "separate" }}
            />
          ),
          TableHead: (props) => (
            <TableHead {...props} sx={{ top: "74px !important" }} />
          ),
          TableRow: (props) => (
            <TableRow
              {...props}
              sx={{
                ["& td"]: {
                  borderBottomColor: (t) => t.palette.divider,
                },
                ["&:not(:hover) a, &:not(:hover) button"]: {
                  opacity: 0,
                },
              }}
            />
          ),
          TableBody: forwardRef((props, ref) => (
            <TableBody {...props} ref={ref} />
          )),
        }}
        style={{
          flex: "0 0 auto",
          height: 100,
          width: "100%",
        }}
        totalCount={submissions?.length}
        data={submissions}
        fixedHeaderContent={() => (
          <TableRow
            sx={{
              background: (t) => t.palette.background.default,

              ["& th"]: {
                borderBottomColor: (t) => t.palette.divider,
              },
            }}
          >
            <TableCell sx={{ paddingLeft: 0 }}>Slug</TableCell>
            {!id && <TableCell>Project</TableCell>}
            <TableCell>Annotator</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Completed Reviews</TableCell>
            <TableCell>Reviews</TableCell>
            <TableCell>Total Review time</TableCell>
            <TableCell>Submitted on</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        )}
        itemContent={(index, submission) =>
          submission ? (
            <SubmissionTableRow
              submission={submission}
              key={submission.id}
              showProject={!id}
              toggleDialog={toggleDialog}
            />
          ) : null
        }
      />
      {!submissionQuery.isLoading && submissions?.length === 0 && (
        <EmptyState>
          <Grid fontSize="large"></Grid>
          <Typography>
            No submissions
            {search ? ` for query ${search}` : id ? " in project" : ""}
          </Typography>
        </EmptyState>
      )}
      <Dialog
        open={!!selectedSubmission}
        onClose={() => toggleDialog(null)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Reviews</DialogTitle>
        <DialogContent>
          {selectedSubmission && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20%" }}>Review Order</TableCell>
                  <TableCell sx={{ width: "20%" }}>Reviewer</TableCell>
                  <TableCell sx={{ width: "20%" }}>Review Time</TableCell>
                  <TableCell sx={{ width: "20%" }}>Review State</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedSubmission.reviews
                  .sort((a, b) => a.order - b.order)
                  .map(
                    (review) =>
                      review && (
                        <TableRow key={review.id}>
                          <TableCell>{review.order}</TableCell>
                          <TableCell>{review.reviewerEmail}</TableCell>
                          <TableCell>
                            {formatTime(review.reviewDurationSeconds ?? 0)}
                          </TableCell>
                          <TableCell>{getReviewState(review)}</TableCell>
                        </TableRow>
                      )
                  )}
              </TableBody>
            </Table>
          )}
          {selectedSubmission?.reviews.length === 0 && (
            <EmptyState>
              <Grid fontSize="large"></Grid>
              <Typography>No reviews for this submission</Typography>
            </EmptyState>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDialog(null)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const SubmissionTableRow: FC<{
  submission: Submission;
  showProject?: boolean;
  toggleDialog: (submission: Submission | null) => void;
}> = memo(({ submission, showProject, toggleDialog }) => {
  const deleteSubmission = trpc.submission.delete.useMutation();
  const utils = trpc.useContext();
  const role = useRole();

  const getCountOfNonRejectedReviews = (submission: Submission) => {
    return submission.reviews.filter(
      (review) => !review.isRejected && !review.isDraft
    ).length;
  };

  return (
    <>
      <TableCell sx={{ paddingLeft: 0, width: 200 }}>
        {submission.assignment.sourceSlug}
      </TableCell>
      {showProject && (
        <TableCell
          sx={{
            width: 100,
            wordBreak: "break-word",
          }}
        >
          {submission.assignment.project.name}
        </TableCell>
      )}
      <TableCell>{submission.annotator?.email}</TableCell>
      <TableCell>
        {submission.annotationDurationSeconds
          ? formatTime(submission.annotationDurationSeconds)
          : "-"}
      </TableCell>

      <TableCell>
        {getCountOfNonRejectedReviews(submission) +
          "/" +
          submission.assignment.project.numberOfReviewsPerSubmission}
      </TableCell>
      <TableCell>
        <IconButton onClick={() => toggleDialog(submission)}>
          {submission.reviews.length > 0 ? <VisibilityIcon /> : "-"}
        </IconButton>
      </TableCell>
      <TableCell>
        {submission.reviewDurationSeconds
          ? formatTime(submission.reviewDurationSeconds)
          : "-"}
      </TableCell>
      <TableCell>
        {submission.submittedAt ? formatDate(submission.submittedAt) : "-"}
      </TableCell>
      <TableCell>
        <StatusChip submission={submission} chipProps={{ variant: "filled" }} />
      </TableCell>
      <TableCell sx={{ paddingRight: 0, whiteSpace: "nowrap" }} align="right">
        <Link
          href={createRouteLink(routes.assignment, {
            slug: submission.assignment.sourceSlug,
            id: submission.id,
          })}
        >
          <Button>View</Button>
        </Link>
        {role === Role.ADMIN && (
          <IconButton
            disabled={deleteSubmission.isLoading}
            onClick={() => {
              useDialogs.getState().setConfirmation({
                title: `Are you sure you want to delete the annotation of ${submission.assignment.sourceSlug} by ${submission.annotator.name}?`,
                description:
                  "This is not reversable and will cause the loss of annotation data",
                onConfirm: async () => {
                  await deleteSubmission.mutateAsync({ id: submission.id });
                  utils.submission.invalidate();
                  utils.project.invalidate();
                },
              });
            }}
          >
            <Trash />
          </IconButton>
        )}
      </TableCell>
    </>
  );
});

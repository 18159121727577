export const SubmissionStateType = {
  IN_PROGRESS: "IN_PROGRESS",
  ANNOTATED: "ANNOTATED",
  REVIEWED: "REVIEWED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
} as const;

export type SubmissionStateType =
  (typeof SubmissionStateType)[keyof typeof SubmissionStateType];

import { colord } from "colord";
export const BaseColors = {
  gold: "#f7c248",
  blue: "#2265f6",
  base: "#7a7e88",
  green: "#38b1a9",
  pink: "#cf4f77",
  orange: "#ef7447",
  purple: "#9366e2",
  rust: "#af6b7d",
  beige: "#f6ded0",
  azure: "#add8e6",
} as const;

export const getRandomColor = (
  base: string,
  randomness = 20,
  val = Math.random()
) => {
  return colord(base)
    .rotate(val * randomness)
    .toHex();
};

export const getToothColor = (base: string, fdi: number, offset = 0) => {
  const pos = fdi % 10;
  const group = pos >= 6 ? 3 : pos >= 4 ? 2 : pos >= 3 ? 1 : 0;
  return colord(base)
    .rotate(group * -20 + offset)
    .toHex();
};

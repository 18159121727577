import { z } from "zod";

import { AnnotationRegion } from "./AnnotationRegion";
import { AnnotationType } from "./AnnotationType";
import { PreFillMode } from "./PreFillMode";

export const assignmentStepSchema = z
  .object({
    id: z.string(),
    name: z.string().min(1),
    instruction: z.string().optional(),
    type: z.nativeEnum(AnnotationType),
    /** @deprecated use perRegion property */
    perFDI: z.boolean().optional(),
    perRegion: z.nativeEnum(AnnotationRegion).optional(),
    color: z.string(),
    preFillMode: z.nativeEnum(PreFillMode).optional(),
    maxPoints: z.number().optional(),
  })
  /** Migrate legacy schemas on parse. */
  .transform((d) => {
    const isLegacyPerFdi = !!d.perFDI;
    delete d.perFDI;
    const cleanedData = d as Omit<typeof d, "perFDI">;
    const perRegion = cleanedData.perRegion
      ? cleanedData.perRegion
      : isLegacyPerFdi
      ? AnnotationRegion.PER_FDI
      : AnnotationRegion.PER_CASE;

    return {
      ...cleanedData,
      perRegion,
    };
  });

export type AssignmentStep = z.infer<typeof assignmentStepSchema>;

export const assignmentConfigurationSchema = z.object({
  instruction: z.string().optional(),
  steps: z.array(assignmentStepSchema),
});
export type AssignmentConfiguration = z.infer<
  typeof assignmentConfigurationSchema
>;

import { Container, Stack, Typography } from "@mui/material";

import { AdminGroupsTable } from "../../components/AdminGroupsTable";
import { AdminInviteTable } from "../../components/AdminInviteTable";
import { AdminUserTable } from "../../components/AdminUserTable";

export const UsersPage = () => {
  return (
    <>
      <Container sx={{ marginY: 2 }} maxWidth={"xl"}>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Typography variant="h4" fontWeight={"bold"} mt={3} mb={3} flex={1}>
            Users
          </Typography>
        </Stack>

        <Typography variant="h6" mt={2} mb={2}>
          Groups
        </Typography>
        <AdminGroupsTable />

        <Typography variant="h6" mt={4} mb={2}>
          Invited users
        </Typography>
        <Typography variant="body2" mb={2}>
          Enter a list of emails to whitelist for signing up. These emails
          should be associated with a Google account, and it will currently not
          send any invite emails.
        </Typography>
        <AdminInviteTable />
        <Typography variant="h6" mt={10} mb={2}>
          Users
        </Typography>
        <AdminUserTable />
      </Container>
    </>
  );
};

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { APIOutput } from "../hooks/trpc";

type Session = APIOutput["user"]["session"] | null;

export const ViewMode = {
  SINGLE: "SINGLE",
  QUADRANTS: "QUADRANTS",
} as const;

export type ViewMode = (typeof ViewMode)[keyof typeof ViewMode];

export const ControlMode = {
  ORBIT: "orbit",
  ARCBALL: "arcball",
} as const;

export type ControlMode = (typeof ControlMode)[keyof typeof ControlMode];

interface AppState {
  session: Session;
  setSession: (session: Session) => void;

  layerListVisible: boolean;

  setLayerListVisible: (to: boolean) => void;

  viewMode: ViewMode;
  setViewMode: (to: ViewMode) => void;

  enableWireframe: boolean;
  setEnableWireframe: (to: boolean) => void;

  controlMode: ControlMode;
  setControlMode: (controlMode: ControlMode) => void;
}

export const useAppState = create<AppState>()(
  devtools(
    persist(
      (set) => ({
        session: null,
        setSession: (token) => set({ session: token }),
        layerListVisible: false,
        setLayerListVisible: (to) => set({ layerListVisible: to }),
        viewMode: ViewMode.SINGLE,
        setViewMode: (to) => set({ viewMode: to }),
        enableWireframe: false,
        setEnableWireframe: (enableWireframe) => set({ enableWireframe }),
        controlMode: ControlMode.ORBIT,
        setControlMode: (controlMode) => set({ controlMode }),
      }),
      {
        name: "appState",
        version: 1,
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) => ![""].includes(key))
          ),
      }
    )
  )
);

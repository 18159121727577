import { Avatar, css, keyframes, Popover, styled } from "@mui/material";
import { Comment as CommentIcon } from "@promaton/icons";
import { FC, useState } from "react";

import type { AvatarData } from "./AvatarData";

export const CommentPopover: FC<{
  isOpen: boolean;
  isHighlighted?: boolean;
  onOpen?: () => void;
  onClose: () => void;
  avatar?: AvatarData;
  renderCard: (props: { onClose?: () => void }) => JSX.Element;
}> = ({
  isOpen,
  isHighlighted = false,
  onClose,
  onOpen,
  avatar,
  renderCard,
}) => {
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  return (
    <>
      <Pinger isHighlighted={isHighlighted}>
        {avatar !== undefined ? (
          <AvatarCommentBadge
            src={avatar.picture}
            imgProps={{
              draggable: false,
            }}
            ref={setAnchor}
            onClick={onOpen}
          >
            {avatar.name?.slice(0, 2)}
          </AvatarCommentBadge>
        ) : (
          <GenericCommentBadge ref={setAnchor} onClick={onOpen}>
            <CommentIcon />
          </GenericCommentBadge>
        )}
      </Pinger>

      <PopoverCard
        open={anchor !== null && isOpen}
        onClose={onClose}
        anchorEl={anchor}
      >
        {renderCard({ onClose })}
      </PopoverCard>
    </>
  );
};

const PopoverCard = styled(Popover)`
  z-index: 999999999;
  cursor: initial;

  .MuiPaper-root {
    max-width: 400px;
    width: 100vw;
    height: auto;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
  }
`;

const GenericCommentBadge = styled("div")`
  display: flex;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  border: 3px solid ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  border-radius: 50%;
`;

const AvatarCommentBadge = styled(Avatar)`
  border: 2px solid ${(props) => props.theme.palette.text.primary};
  cursor: pointer;
`;

const Pinger = styled("div")<{ isHighlighted: boolean }>`
  position: relative;

  & > :last-child {
    position: relative;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background-color: ${(props) => props.theme.palette.primary.main};
    opacity: 0.75;
    animation: ${(props) => (props.isHighlighted ? pingAnimation : "none")};
  }
`;

const pingKeyframe = keyframes`
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
`;

// Source: https://tailwindcss.com/docs/animation#ping
const pingAnimation = css`
  ${pingKeyframe} 1s cubic-bezier(0, 0, 0.2, 1) infinite forwards;
`;

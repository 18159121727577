import {
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Search, Trash } from "@promaton/icons";
import { FC, forwardRef, memo, useMemo } from "react";
import { useForm } from "react-hook-form";
import { TableVirtuoso } from "react-virtuoso";

import { APIOutput, trpc } from "../hooks/trpc";
import { Role } from "../hooks/useRole";
import { useAppState } from "../stores/useAppState";
import { formatDate } from "../utils/dateFormatter";
import { EmptyState } from "./EmptyState";

type User = APIOutput["user"]["list"]["result"][0];

export const AdminUserTable: FC<{}> = () => {
  const { register, watch } = useForm<{
    search: string;
  }>();
  const search = watch("search");
  const userQuery = trpc.user.list.useInfiniteQuery(
    {
      search: search || undefined,
    },
    {
      getNextPageParam: (lastPage) => lastPage.cursor,
    }
  );

  const users = useMemo(() => {
    return userQuery.data?.pages.map((p) => p.result).flat();
  }, [userQuery.data]);

  return (
    <>
      <InputBase
        startAdornment={<Search sx={{ marginRight: 1 }} />}
        type="search"
        placeholder="Search by email"
        autoComplete="off"
        {...register("search", {
          minLength: 1,
        })}
        fullWidth
        sx={{
          flex: 1,
          padding: 1,
          paddingLeft: 2,
          background: (t) => t.palette.action.hover,
          borderRadius: (t) => t.spacing(1),
        }}
      />
      {(users?.length || 0) > 0 && (
        <TableVirtuoso
          useWindowScroll
          endReached={() => {
            userQuery.fetchNextPage();
          }}
          components={{
            Scroller: forwardRef((props, ref) => (
              <div {...props} ref={ref}></div>
            )),
            Table: (props) => (
              <Table {...props} style={{ borderCollapse: "separate" }} />
            ),
            TableHead: (props) => (
              <TableHead {...props} sx={{ top: "74px !important" }} />
            ),
            TableRow: (props) => (
              <TableRow
                {...props}
                sx={{
                  ["& td"]: {
                    borderBottomColor: (t) => t.palette.divider,
                  },
                  ["&:not(:hover) td:last-child button"]: {
                    opacity: 0,
                  },
                }}
              />
            ),
            TableBody: forwardRef((props, ref) => (
              <TableBody {...props} ref={ref} />
            )),
          }}
          style={{
            flex: "0 0 auto",
            height: 100,
            width: "100%",
          }}
          totalCount={users?.length}
          data={users}
          fixedHeaderContent={() => (
            <TableRow
              sx={{
                background: (t) => t.palette.background.default,

                ["& th"]: {
                  borderBottomColor: (t) => t.palette.divider,
                },
              }}
            >
              <TableCell sx={{ paddingLeft: 0 }}>Email</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Role</TableCell>
              <TableCell sx={{ width: 50 }}></TableCell>
            </TableRow>
          )}
          itemContent={(index, user) =>
            user ? <UserTableRow user={user} key={user.Username} /> : null
          }
        />
      )}
      {users?.length === 0 && (
        <EmptyState>
          <Typography>No open invitations</Typography>
        </EmptyState>
      )}
    </>
  );
};

export const UserTableRow: FC<{ user: User }> = memo(({ user }) => {
  const session = useAppState((s) => s.session);
  const utils = trpc.useContext();
  const updateUser = trpc.user.updateUser.useMutation();
  const updateInvite = trpc.user.updateInvite.useMutation();
  const deleteUser = trpc.user.deleteUser.useMutation();
  const deleteInvite = trpc.user.deleteInvite.useMutation();
  const role = useMemo(() => {
    if (!user.groups) return undefined;
    if (user.groups.includes("admin")) return "admin";
    if (user.groups.includes("reviewer")) return "reviewer";
    if (user.groups.includes("annotator")) return "annotator";
  }, [user.groups]);

  return (
    <>
      <TableCell sx={{ paddingLeft: 0, width: 300 }}>{user.email}</TableCell>
      <TableCell>{user.Username}</TableCell>
      <TableCell>{formatDate(user.UserCreateDate || "")}</TableCell>
      <TableCell sx={{ width: 150 }}>
        <Select
          size="small"
          value={role || ""}
          fullWidth
          onChange={async (_, value) => {
            if (!user.Username) return;
            const role = _.target.value as Role;
            console.log(_, value);
            await updateUser.mutateAsync({
              user: user.Username,
              role,
            });
            try {
              user.email &&
                (await updateInvite.mutateAsync({
                  email: user.email,
                  role,
                }));
            } catch (e: any) {
              console.warn(e);
            }
            utils.user.list.invalidate();
          }}
        >
          <MenuItem value={Role.ANNOTATOR}>Annotator</MenuItem>
          <MenuItem value={Role.REVIEWER}>Reviewer</MenuItem>
          <MenuItem value={Role.ADMIN}>Admin</MenuItem>
        </Select>
      </TableCell>
      <TableCell align="right">
        <IconButton
          disabled={deleteUser.isLoading || user.email === session?.email}
          onClick={async () => {
            if (!user.Username) return;
            await deleteUser.mutateAsync({ user: user.Username });
            utils.user.list.invalidate();
            user.email &&
              (await deleteInvite.mutateAsync({
                email: user.email,
              }));
          }}
        >
          <Trash />
        </IconButton>
      </TableCell>
    </>
  );
});

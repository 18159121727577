export const getIsFinalReview = (
  submission: { reviews: Array<{ isRejected: boolean; isDraft: boolean }> },
  numberOfReviewsPerSubmission = 0
) => {
  const currentSubmittedReviewCount =
    submission.reviews?.filter(
      (review) => !review.isRejected && !review.isDraft
    ).length ?? 0;

  return (
    (numberOfReviewsPerSubmission !== 0 &&
      numberOfReviewsPerSubmission - currentSubmittedReviewCount) === 1
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CaretDown, CaretUp, Copy, Trash } from "@promaton/icons";
import { sentenceCase } from "change-case";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { shallow } from "zustand/shallow";

import { trpc } from "../hooks/trpc";
import { AnnotationRegion } from "../static/AnnotationRegion";
import { AnnotationType } from "../static/AnnotationType";
import {
  AssignmentStep,
  assignmentStepSchema,
} from "../static/AssignmentConfiguration";
import { PreFillMode } from "../static/PreFillMode";
import { ColorPicker } from "./ColorPicker";

export const LINE_ANNOTATION_TYPES: AnnotationType[] = [
  AnnotationType.LINE,
  AnnotationType.RADIUS_LINE,
];

export const AssignmentConfiguratorStep: FC<{
  step: AssignmentStep;
  index: number;
  onMove: (increment: number) => void;
  onCopy: () => void;
  onDelete: () => void;
  onUpdate: (data: AssignmentStep) => void;
}> = ({ step, onMove, onCopy, onDelete, onUpdate, index }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<AssignmentStep>({
    resolver: zodResolver(assignmentStepSchema),
    defaultValues: step,
  });

  const labelQuery = trpc.annotationLabel.getAll.useQuery();
  const labels = labelQuery.data?.labels || [];
  const data = watch();

  useEffect(() => {
    if (!shallow(data, step)) {
      onUpdate(data);
    }
  }, [data]);

  return (
    <Card variant="outlined" sx={{ borderRadius: 2 }}>
      <CardContent
        sx={{
          background: `linear-gradient(170deg, ${step.color}22 0%, transparent 70%)`,
        }}
      >
        <Stack flexDirection="row" alignItems="center" mb={2} gap={1}>
          <Typography fontWeight={"bold"}>Step {index + 1}</Typography>
        </Stack>
        <Stack flexDirection={"row"} gap={2}>
          <Stack gap={2} sx={{ flex: 1 }}>
            <Stack flexDirection="row" gap={2} alignItems={"center"}>
              <Controller
                name="color"
                control={control}
                render={({ field }) => {
                  return <ColorPicker {...field} type="color" />;
                }}
              />
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    {...field}
                    disabled={labelQuery.isLoading}
                    options={labels}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Step name (request new annotation label options w/ data platform team)"
                        error={!!errors.name}
                      />
                    )}
                    onChange={(_, value) => field.onChange(value)}
                  />
                )}
              />
            </Stack>
            <Stack flexDirection="row" gap={2}>
              <FormControl fullWidth>
                <InputLabel>Annotation Type</InputLabel>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select label="Annotation Type" {...field}>
                      {Object.values(AnnotationType).map((id) => (
                        <MenuItem value={id} key={id}>
                          {sentenceCase(id)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              {data.type === AnnotationType.PAINT_MESH && (
                <FormControl fullWidth sx={{ maxWidth: 300 }}>
                  <InputLabel>Pre-Fill Mode</InputLabel>
                  <Controller
                    name="preFillMode"
                    control={control}
                    render={({ field }) => (
                      <Select label="Prefill mode" {...field}>
                        {Object.values(PreFillMode).map((id) => (
                          <MenuItem value={id} key={id}>
                            {sentenceCase(id)}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              )}
              {LINE_ANNOTATION_TYPES.includes(data.type) && (
                <FormControl fullWidth sx={{ maxWidth: 300 }}>
                  <Controller
                    name="maxPoints"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Max. points"
                        type="number"
                        {...field}
                        inputProps={{
                          min: 1,
                          max: 100,
                        }}
                        onChange={(e) => {
                          field.onChange(parseInt(e.target.value, 10));
                        }}
                        error={!!errors.maxPoints}
                      />
                    )}
                  />
                </FormControl>
              )}
              <FormControl fullWidth sx={{ maxWidth: 300 }}>
                <InputLabel>Annotation Region</InputLabel>
                <Controller
                  name="perRegion"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select label="Annotation Region" {...field}>
                      {Object.values(AnnotationRegion).map((id) => (
                        <MenuItem value={id} key={id}>
                          {sentenceCase(id)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Stack>
            <Controller
              name="instruction"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Instruction (optional)"
                  {...field}
                  error={!!errors.instruction}
                />
              )}
            />
          </Stack>
          <Stack gap={2}>
            <IconButton onClick={() => onMove(-1)}>
              <CaretUp />
            </IconButton>
            <IconButton onClick={() => onMove(1)}>
              <CaretDown />
            </IconButton>
            <IconButton onClick={onDelete}>
              <Trash />
            </IconButton>
            <IconButton onClick={onCopy}>
              <Copy />
            </IconButton>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { FC, memo } from "react";
import { useTimeoutFn } from "react-use";

import { Notification, useDialogs } from "./useDialogs";

export const GlobalDialogs: FC<{}> = memo(() => {
  const confirmation = useDialogs((s) => s.confirmation);
  const setConfirmation = useDialogs((s) => s.setConfirmation);
  const notifications = useDialogs((s) => s.notifications);
  return (
    <>
      {confirmation && (
        <Dialog
          open
          onClose={() => {
            confirmation.onCancel?.();
            setConfirmation(null);
          }}
        >
          <DialogTitle>{confirmation.title}</DialogTitle>

          <DialogContent>
            <DialogContentText>{confirmation.description}</DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                confirmation.onCancel?.();
                setConfirmation(null);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                confirmation.onConfirm();
                setConfirmation(null);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Stack
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          zIndex: (t) => t.zIndex.modal + 1,
          maxWidth: 300,
        }}
      >
        <AnimatePresence>
          {notifications.map((n) => (
            <NotificationBanner notification={n} key={n.id} />
          ))}
        </AnimatePresence>
      </Stack>
    </>
  );
});

const MotionAlert = motion(Alert);

const NotificationBanner: FC<{ notification: Notification }> = ({
  notification: n,
}) => {
  const deleteNotification = useDialogs((s) => s.deleteNotification);

  useTimeoutFn(() => {
    deleteNotification(n);
  }, 5000);

  return (
    <MotionAlert
      layout
      initial={{
        opacity: 0,
        x: 0,
        y: 100,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      exit={{
        opacity: 0,
        x: 400,
        y: 0,
      }}
      transition={{
        duration: 0.25,
      }}
      variant="outlined"
      sx={{
        m: 2,
        mt: 0,
        borderRadius: 3,
        background: (t) => t.palette.background.paper,
        borderColor: (t) => t.palette.divider,
      }}
      severity={n.color}
      onClose={() => deleteNotification(n)}
    >
      {n.text}
    </MotionAlert>
  );
};

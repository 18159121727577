import { Button, ButtonProps } from "@mui/material";
import { FC, memo, useMemo } from "react";
import { useAsync } from "react-use";
import { useLocation } from "wouter";

import { trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";

export const LoginButton: FC<{ label?: string } & ButtonProps> = memo(
  ({ label = "Login", ...props }) => {
    const [_] = useLocation();
    const createToken = trpc.user.login.useMutation();
    const setSession = useAppState((s) => s.setSession);
    const utils = trpc.useContext();
    const loginUrl = useMemo(() => {
      const redirect = new URLSearchParams(location.search).get("redirect");
      const params = new URLSearchParams({
        client_id: import.meta.env.VITE_COGNITO_APP,
        response_type: "code",
        scope: "aws.cognito.signin.user.admin email openid phone profile",
        redirect_uri: `${location.origin}/login`,
      });
      redirect && params.append("state", redirect);
      return `https://${
        import.meta.env.VITE_COGNITO_HOST
      }/authorize?${params.toString()}`;
    }, []);

    useAsync(async () => {
      const authorizationToken = new URLSearchParams(location.search).get(
        "code"
      );
      if (!authorizationToken) return;
      const payload = await createToken.mutateAsync({
        authorizationToken,
        redirectURL: `${location.origin}/login`,
      });
      utils.user.session.setData(undefined, () => payload);
      setSession(payload);
    }, []);

    return (
      <Button
        href={loginUrl}
        variant="outlined"
        disabled={createToken.isLoading}
        {...props}
        sx={{ textTransform: "none", fontWeight: "bold", ...props.sx }}
      >
        {label}
      </Button>
    );
  }
);

import {
  Button,
  Card,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { FC, forwardRef, useMemo } from "react";
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";

import { FormContainer } from "./FormFlow";

StylesManager.applyTheme("defaultV2");

const FormEditor: FC<
  TextFieldProps & {
    errorText?: string;
    data?: object;
    onRefresh?: () => void;
  }
> = forwardRef(({ errorText, onRefresh, data, ...rest }, ref) => {
  const model = useMemo(() => {
    if (!data) return null;
    const model = new Model(data);
    return model;
  }, [data]);

  return (
    <>
      <Stack gap={2} flex={1} sx={{ width: "100%" }}>
        <Stack
          flexDirection={"row"}
          gap={2}
          sx={{ width: "100%" }}
          alignItems={"flex-end"}
        >
          <Stack gap={1} flex={1}>
            <Typography variant="h6">Metadata project configuration</Typography>
            <Typography variant="body1">
              Below, please paste a form configuration JSON as configured by the{" "}
              <a
                href={"https://surveyjs.io/create-free-survey"}
                target="_blank"
                rel="noreferrer"
              >
                Survey.js form creator
              </a>
              .
            </Typography>
          </Stack>
          <Button
            variant={"outlined"}
            onClick={() => onRefresh?.()}
            sx={{ width: 200 }}
          >
            Preview
          </Button>
        </Stack>
      </Stack>
      <Card sx={{ width: "100%" }} variant="outlined">
        <Stack
          flexDirection={"row"}
          alignItems={"stretch"}
          gap={1}
          sx={{ height: 500, width: "100%" }}
        >
          <TextField
            sx={{ flex: 1, height: "100%", ["& > *"]: { height: "100%" } }}
            ref={ref}
            error={!!errorText}
            helperText={errorText}
            fullWidth
            variant="filled"
            multiline
            minRows={20}
            maxRows={20}
            inputProps={{
              sx: {
                fontFamily: "monospace",
                height: "100%",
              },
            }}
            placeholder="Assignment configuration"
            {...rest}
          />

          <FormContainer
            sx={{
              flex: 1,
            }}
          >
            {model && <Survey model={model} />}
          </FormContainer>
        </Stack>
      </Card>
    </>
  );
});

export default FormEditor;

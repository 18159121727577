import { useCallback, useMemo, useState } from "react";

/**
 * Hook to store and retrieve state (string) in both search params and session storage.
 * The search parameter takes precedence but the session storage persists
 * throughout navigation and page reloads.
 */
export function useQueryAndSessionState(key: string, defaultValue?: string) {
  const initialValue = useMemo(() => {
    const searchParam = new URLSearchParams(window.location.search).get(key);
    const sessionValue = sessionStorage.getItem(key);

    if (sessionValue && !searchParam) {
      // restore search param from session storage
      setQueryParam(key, sessionValue);
    }

    return searchParam ?? sessionValue ?? defaultValue;
  }, []);

  const [state, setState] = useState(initialValue);

  const setQueryAndSessionState = useCallback(
    (value?: string) => {
      setQueryParam(key, value);
      if (value) {
        sessionStorage.setItem(key, value);
      } else {
        sessionStorage.removeItem(key);
      }
      setState(value);
    },
    [key]
  );

  return [state, setQueryAndSessionState] as const;
}

const setQueryParam = (key: string, value?: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  if (value) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${searchParams.toString()}`
  );
};

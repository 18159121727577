import { Chip, ChipProps, Menu, Stack } from "@mui/material";
import { CaretDown } from "@promaton/icons";
import { sentenceCase } from "change-case";
import { FC, useMemo, useState } from "react";

import { type APIOutput, trpc } from "../hooks/trpc";
import { Role, useRole } from "../hooks/useRole";
import { useSubmissionStateUpdate } from "../hooks/useSubmissionStateUpdate";
import { SubmissionStateType } from "../static/SubmissionStateType";
import { getIsFinalReview } from "../utils/utils";

type Submission = APIOutput["submission"]["get"];
type SubmissionState = Submission["state"];

export const statusColor: { [key in SubmissionState]: any } = {
  IN_PROGRESS: "warning",
  ANNOTATED: "secondary",
  REVIEWED: "info",
  APPROVED: "primary",
  REJECTED: "error",
};

export const StatusChip: FC<{
  submission: Pick<Submission, "id" | "state" | "assignment" | "reviews">;
  chipProps?: ChipProps;
  editable?: boolean;
}> = ({ submission, chipProps, editable }) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const role = useRole();
  const { handleSubmissionStateUpdate } = useSubmissionStateUpdate();
  const draftReviewQuery = trpc.review.fetchDraft.useQuery({
    id: submission.id,
  });

  const allowedState = useMemo<SubmissionState[]>(() => {
    if (role === Role.REVIEWER) {
      return getIsFinalReview(
        submission,
        submission.assignment.project.numberOfReviewsPerSubmission
      )
        ? [
            SubmissionStateType.ANNOTATED,
            SubmissionStateType.APPROVED,
            SubmissionStateType.REJECTED,
          ]
        : [
            SubmissionStateType.ANNOTATED,
            SubmissionStateType.REVIEWED,
            SubmissionStateType.REJECTED,
          ];
    } else if (role === Role.ANNOTATOR) {
      return [SubmissionStateType.IN_PROGRESS, SubmissionStateType.ANNOTATED];
    } else {
      return [
        SubmissionStateType.IN_PROGRESS,
        SubmissionStateType.ANNOTATED,
        SubmissionStateType.REVIEWED,
        SubmissionStateType.APPROVED,
        SubmissionStateType.REJECTED,
      ];
    }
  }, [role]);

  return (
    <>
      <Chip
        onClick={
          editable
            ? (e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchor(e.currentTarget);
              }
            : undefined
        }
        variant="outlined"
        {...chipProps}
        onDelete={
          editable
            ? () => {
                return;
              }
            : undefined
        }
        sx={{ fontWeight: "bold" }}
        deleteIcon={<CaretDown />}
        color={statusColor[submission.state]}
        label={sentenceCase(submission.state)}
      />
      <Menu
        onClick={(e) => e.stopPropagation()}
        id="menu-appbar"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          variant: "outlined",
          elevation: undefined,
          sx: {
            padding: 0,
            borderRadius: 4,
          },
        }}
        open={Boolean(anchor)}
        onClose={() => {
          setAnchor(null);
        }}
      >
        <Stack px={1.5} py={0.5} gap={1} sx={{ width: 150 }}>
          {allowedState.map((state) => (
            <Chip
              variant={state === submission.state ? "filled" : "outlined"}
              onClick={() =>
                handleSubmissionStateUpdate(
                  state,
                  submission,
                  draftReviewQuery.data?.id
                )
              }
              sx={{ fontWeight: "bold", height: 40, borderRadius: 2 }}
              key={state}
              color={statusColor[state]}
              label={sentenceCase(state)}
            />
          ))}
        </Stack>
      </Menu>
    </>
  );
};

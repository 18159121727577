import { createTheme, ThemeOptions } from "@mui/material";

const shared: ThemeOptions = {
  typography: {
    fontFamily: "Inter, sans-serif",
    button: {
      textTransform: "none",
    },
  },
  palette: {
    grey: {
      50: "#F8FAFC",
      100: "#F1F5F9",
      200: "#E2E8F0",
      300: "#CBD5E1",
      400: "#94A3B8",
      500: "#64748B",
      600: "#475569",
      700: "#334155",
      800: "#1E293B",
      900: "#0F172A",
    },
  },
};

export const darkTheme = createTheme({
  ...shared,
  palette: {
    ...shared.palette,
    mode: "dark",
    primary: {
      main: "#93C1F9",
    },
    success: {
      main: "#86EFAC",
    },
    warning: {
      main: "#F59E0B",
    },
    background: {
      default: "#0F172A",
      paper: "#0F172A",
    },
    text: {
      primary: "#ffffff",
      secondary: "#E2E8F0",
    },
  },
});

export const lightTheme = createTheme({
  ...shared,
  palette: {
    ...shared.palette,
    mode: "light",
    primary: {
      main: "#1874EC",
    },
    success: {
      main: "#4ADE80",
    },
    warning: {
      main: "#F59E0B",
    },
    background: {
      default: "#F8FAFC",
      paper: "#ffffff",
    },
    text: {
      primary: "#475569",
      secondary: "#94A3B8",
    },
  },
});

import "@promaton/frontend-common/src/sentry";

import { ThemeProvider, useMediaQuery } from "@mui/material";
import { darkTheme, lightTheme } from "@promaton/theme";
import React from "react";
import ReactDOM from "react-dom/client";
import { registerSW } from "virtual:pwa-register";

import App from "./App";
import { ClientProvider } from "./components/ClientProvider";

registerSW();

ReactDOM.createRoot(document.getElementById("root")!).render(<AppContainer />);

function AppContainer() {
  const systemDark = useMediaQuery("(prefers-color-scheme: dark)");

  return (
    <React.StrictMode>
      <ThemeProvider theme={systemDark ? darkTheme : lightTheme}>
        <ClientProvider>
          <App />
        </ClientProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

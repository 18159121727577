import { styled } from "@mui/material";

export const ColorPicker = styled("input")(
  ({ theme }) => `
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  background: none;
  border-radius: 100%;
  overflow: hidden;
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  flex: 0 0 auto;
  cursor: pointer;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
  }
`
);

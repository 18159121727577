import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "wouter";

import { trpc } from "../hooks/trpc";
import { useRole } from "../hooks/useRole";
import { useAppState } from "../stores/useAppState";
export function Profile() {
  const role = useRole();
  const session = useAppState((s) => s.session);
  const setSession = useAppState((s) => s.setSession);
  const logout = trpc.user.logout.useMutation();
  const utils = trpc.useContext();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const sessionCheck = trpc.user.session.useQuery(undefined, {
    retry: false,
    trpc: {},
  });

  useEffect(() => {
    if (sessionCheck.isLoading) return;
    if (
      (sessionCheck.error &&
        sessionCheck.error.data?.code === "UNAUTHORIZED") ||
      (sessionCheck.isSuccess && !sessionCheck.data)
    ) {
      handleLogout();
    }
  }, [sessionCheck.error, sessionCheck.isSuccess]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    await logout.mutateAsync();
    setSession(null);
    utils.user.session.invalidate();
    handleCloseUserMenu();
  };

  if (!session) return <Avatar>??</Avatar>;

  const avatar = (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Avatar
        src={session.picture as string}
        imgProps={{
          draggable: false,
          referrerPolicy: "no-referrer",
        }}
      >
        {((session.name as string) ?? (session.email as string) ?? "").slice(
          0,
          2
        )}
      </Avatar>
    </Badge>
  );

  return (
    <Box
      component="div"
      sx={{
        flexGrow: 0,
        width: "initial",
      }}
    >
      <IconButton
        onClick={handleOpenUserMenu}
        sx={{
          p: 0,
        }}
      >
        {avatar}
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {role !== "annotator" && (
          <Link href="/">
            <MenuItem>
              <Typography textAlign="center">Home</Typography>
            </MenuItem>
          </Link>
        )}
        {role !== "annotator" && (
          <Link href="/review">
            <MenuItem>
              <Typography textAlign="center">Submissions</Typography>
            </MenuItem>
          </Link>
        )}
        {role === "admin" && (
          <Link href="/admin">
            <MenuItem>
              <Typography textAlign="center">Admin</Typography>
            </MenuItem>
          </Link>
        )}
        <MenuItem onClick={handleLogout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Stack,
  Typography,
  Unstable_Grid2 as Grid2,
} from "@mui/material";
import { Folder } from "@promaton/icons";
import { sentenceCase } from "change-case";
import { FC } from "react";
import { Link } from "wouter";

import { ProjectType } from "../../static/ProjectType";
import { formatDate } from "../../utils/dateFormatter";
import { createRouteLink, routes } from "../routes";

export const ProjectCard: FC<{ project: ProjectType }> = ({ project }) => {
  return (
    <Grid2 xs={12} md={6} lg={4}>
      <Card
        sx={{
          cursor: "pointer",
          borderRadius: 3,
          boxShadow: "0 0.5rem 0.75rem -0.5rem rgba(0,0,0,0.15)",
          ["& .hidden"]: {
            opacity: 0,
          },
          ["&:hover"]: {
            boxShadow: "0 1.5rem 1rem -0.75rem rgba(0,0,0,0.1)",
            ["& .hidden"]: {
              opacity: 1,
            },
          },
        }}
      >
        <Link
          href={createRouteLink(routes.projectDetail, {
            id: project.id,
          })}
        >
          <CardActionArea>
            <CardContent>
              <Stack flexDirection="row" gap={2} alignItems="flex-start">
                <Avatar>
                  <Folder />
                </Avatar>
                <Stack gap={0.5}>
                  <Typography
                    title={project.name}
                    variant="h6"
                    sx={{
                      wordBreak: "break-word",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      lineClamp: 2,
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {project.name}
                  </Typography>
                  <Typography variant="body2">
                    {project._count.assignments} cases ·{" "}
                    {formatDate(project.createdAt)}
                    {project.createdBy && ` · ${project.createdBy}`}
                  </Typography>
                  <Stack flexDirection="row">
                    <Chip
                      variant="outlined"
                      size="small"
                      label={sentenceCase(project.dataSource)}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    </Grid2>
  );
};

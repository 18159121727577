import {
  browserTracingIntegration,
  captureConsoleIntegration,
  init,
  replayCanvasIntegration,
  replayIntegration,
} from "@sentry/react";

if (import.meta.env.PROD) {
  init({
    release: `frontend@${import.meta.env.VITE_BUILD_VERSION}`,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      browserTracingIntegration(),
      replayIntegration(),
      replayCanvasIntegration(),
      captureConsoleIntegration({
        levels: ["warn", "error"],
      }),
    ],
    tracesSampleRate: 0.2,
    tracePropagationTargets: [window.location.origin],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

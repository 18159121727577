export enum Environment {
  DEV = "DEV",
  PROD = "PROD",
  LOCALDEV = "LOCALDEV",
}

export function getEnvironment(): Environment {
  const currentUrl = window.location.href;

  if (currentUrl.includes("annotator-dev")) {
    return Environment.DEV;
  }

  if (import.meta.env.DEV) {
    return Environment.LOCALDEV;
  }

  return Environment.PROD;
}

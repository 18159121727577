import { ExtractRouteParams, Path } from "wouter";

export const routes = {
  root: "/",
  assignment: "/assignment/:id/:slug",
  login: "/login",
  admin: "/admin/:any*",
  adminRoot: "/admin",
  projects: "/admin/project",
  newProject: "/admin/project/new",
  projectEdit: "/admin/project/:id/edit",
  projectDetail: "/admin/project/:id",
  users: "/admin/users",
  group: "/admin/users/:id",
  templates: "/admin/templates",
  template: "/admin/templates/:id",
  review: "/review",
} as const;

export function createRouteLink<T extends Path>(
  path: T,
  params: ExtractRouteParams<T>
) {
  // eslint-disable-next-line no-useless-escape
  let output = path.replaceAll(/[\+\*\?]/g, "") as string;

  Object.entries(params).forEach(([param, value]) => {
    output = output.replace(`:${param}`, `${value}`);
  });

  return output;
}

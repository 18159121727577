import { useLocation } from "wouter";

import { SubmissionStateType } from "../static/SubmissionStateType";
import { type APIOutput, trpc } from "./trpc";

type Submission = APIOutput["submission"]["get"];

export const useSubmissionStateUpdate = () => {
  const updateSubmission = trpc.submission.updateState.useMutation();
  const utils = trpc.useContext();
  const [_, setLocation] = useLocation();

  const handleSubmissionStateUpdate = async (
    state: Submission["state"],
    submission: { id: string },
    draftReviewId?: string
  ): Promise<void> => {
    await updateSubmission.mutateAsync({
      id: submission.id,
      state,
      draftReviewId,
    });
    utils.submission.invalidate();
    utils.review.invalidate();

    if (state !== SubmissionStateType.IN_PROGRESS) {
      setLocation("/");
    }
  };

  return { handleSubmissionStateUpdate };
};

import { Fzf, FzfResultItem } from "fzf";
import produce from "immer";
import { create } from "zustand";

type Command = {
  name: string;
  description: string;
  keywords?: string;
  action: () => void;
};

interface CommandStore {
  commands: Record<string, Command>;
  index?: Fzf<Command[]>;
  updateCommand: (id: string, command: Command) => void;
  removeCommand: (id: string) => void;
  searchCommands: (searchTerm: string) => FzfResultItem<Command>[];
  updateIndex: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const useCommandPalette = create<CommandStore>((set, get) => ({
  commands: {},
  index: undefined,
  updateCommand: (id, command) => {
    const state = produce<CommandStore>((state) => {
      state.commands[id] = command;
    })(get());
    set(state);
    state.updateIndex();
  },
  removeCommand: (id) => {
    const state = produce<CommandStore>((state) => {
      delete state.commands[id];
    })(get());
    set(state);
    state.updateIndex();
  },

  updateIndex: () => {
    const state = get();
    set({
      ...state,
      index: new Fzf(Object.values(state.commands), {
        casing: "case-insensitive",

        selector: (command) =>
          `${command.name} ${command.description} ${command.keywords}`,
      }),
    });
  },

  searchCommands: (searchTerm) => {
    const state = get();
    if (!state.index) {
      return [];
    }
    return state.index.find(searchTerm);
  },
  open: false,
  setOpen: (open) => set({ open }),
}));

import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Trash } from "@promaton/icons";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { Link } from "wouter";

import {
  AssignmentIcon,
  AssignmentTypeName,
} from "../../components/AssignmentCard";
import { APIOutput, trpc } from "../../hooks/trpc";
import { AssignmentType } from "../../static/AssignmentType";
import { createRouteLink, routes } from "../routes";

export const TemplatesPage = () => {
  return (
    <>
      <Container sx={{ marginY: 2 }} maxWidth={"xl"}>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Typography variant="h4" fontWeight={"bold"} mt={3} mb={3} flex={1}>
            Templates
          </Typography>
        </Stack>

        <TemplatesGrid />
      </Container>
    </>
  );
};

type template = APIOutput["template"]["list"][0];
const typeOptions: template["type"][] = Object.values(AssignmentType);

export const TemplatesGrid: FC<{}> = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    name: string;
    type: template["type"];
  }>();
  const templates = trpc.template.list.useQuery({});
  const createTemplate = trpc.template.create.useMutation();
  const utils = trpc.useContext();
  const deleteTemplate = trpc.template.delete.useMutation();

  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          await createTemplate.mutateAsync({
            name: data.name,
            type: data.type,
            data: {},
          });
          utils.template.list.invalidate();
          reset();
        })}
      >
        <Stack flexDirection={"row"} gap={2} mb={3}>
          <InputBase
            placeholder="Add template"
            autoComplete="off"
            {...register("name", {
              minLength: 1,
            })}
            fullWidth
            sx={{
              flex: 1,
              padding: 1,
              paddingLeft: 2,
              background: (t) => t.palette.action.hover,
              borderRadius: (t) => t.spacing(1),
            }}
          />
          <FormControl error={!!errors.type}>
            <InputLabel>Project type</InputLabel>
            <Select
              sx={{ width: 200 }}
              {...register("type")}
              error={!!errors.type}
              label={"Project type"}
            >
              {typeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {AssignmentTypeName[option]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button variant="contained" type="submit">
            Create
          </Button>
        </Stack>
      </form>

      <Grid2 container spacing={2} pb={2}>
        {templates.data?.map((template) => (
          <Grid2 xs={12} md={6} lg={4} key={template.id}>
            <Card
              sx={{
                cursor: "pointer",
                borderRadius: 3,
                boxShadow: "0 0.5rem 0.75rem -0.5rem rgba(0,0,0,0.15)",
                ["& .hidden"]: {
                  opacity: 0,
                },
                ["&:hover"]: {
                  boxShadow: "0 1.5rem 1rem -0.75rem rgba(0,0,0,0.1)",
                  ["& .hidden"]: {
                    opacity: 1,
                  },
                },
              }}
            >
              <Link
                href={createRouteLink(routes.template, {
                  id: template.id,
                })}
              >
                <CardActionArea>
                  <CardContent>
                    <Stack flexDirection="row" gap={2} alignItems="center">
                      <Avatar>{AssignmentIcon[template.type]}</Avatar>
                      <Stack flex={1}>
                        <Typography variant="h6">{template.name}</Typography>
                        <Typography variant="body2">
                          {AssignmentTypeName[template.type]}
                        </Typography>
                      </Stack>
                      <IconButton
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          await deleteTemplate.mutateAsync({
                            name: template.name,
                          });
                          utils.template.list.invalidate();
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </>
  );
};

import { Stack, Typography } from "@mui/material";

const DevOverlayRibbon = () => {
  return (
    <Stack
      sx={{
        opacity: "75%",
        position: "fixed",
        bottom: "-200px",
        right: "-25px",
        width: "150px",
        height: "500px",
        zIndex: 9999,
        pointerEvents: "none",
        transform: "rotate(45deg)",
        backgroundColor: "warning.main",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        boxShadow: 3,
      }}
    >
      <Typography
        sx={{
          transform: "rotate(-90deg)",
        }}
        variant="h4"
      >
        DEV
      </Typography>
    </Stack>
  );
};

export default DevOverlayRibbon;

import { FileType } from "@promaton/scan-viewer";

import { AssignmentStep } from "../static/AssignmentConfiguration";
import { DEFAULT_SEGMENT_NAME } from "../stores/useAnnotations";

export const getGeometryName = (step: string, segment: string) => {
  return segment === DEFAULT_SEGMENT_NAME ? step : `${step}-${segment}`;
};

export const getFileName = (name: string, fileType: FileType) => {
  return `${name}.${fileType}`;
};

export const getFileTypeForStep = (step: AssignmentStep) => {
  switch (step.type) {
    case "PAINT_MESH":
      return FileType.STL;
    default:
      return FileType.JSON;
  }
};

import { useTheme } from "@mui/material";
import type { Property } from "csstype";
import { FC, memo, useEffect, useMemo } from "react";

import brush from "./cursor/brush.svg?raw";
import comment from "./cursor/comment.svg?raw";
import fill from "./cursor/fill.svg?raw";
import pen from "./cursor/pen.svg?raw";

const cursors = {
  brush,
  comment,
  fill,
  pen,
} as const;

export const CustomCursor: FC<{
  active?: boolean;
  cursor: keyof typeof cursors | Property.Cursor;
  important?: boolean;
}> = memo(({ cursor, active = true }) => {
  const theme = useTheme();

  const cursorStyle = useMemo(() => {
    if (!(cursor in cursors)) {
      return cursor;
    }

    const cursorSvg = cursors[cursor as keyof typeof cursors];
    const cursorSvgWithColor = cursorSvg
      .replaceAll(/black/g, theme.palette.background.paper)
      .replaceAll(/white/g, theme.palette.text.primary);
    const blob = new Blob([cursorSvgWithColor], { type: "image/svg+xml" });
    return `url(${URL.createObjectURL(blob)}) 0 0, auto`;
  }, [cursor, theme]);

  useEffect(() => {
    const stylesheet = document.createElement("style");
    stylesheet.innerText = `
      body.cursor-${cursor} {
        cursor: ${cursorStyle} !important};
      }
    `;
    document.head.appendChild(stylesheet);
    document.body.classList.toggle(`cursor-${cursor}`, active);

    return () => {
      document.head.removeChild(stylesheet);
      document.body.classList.toggle(`cursor-${cursor}`, false);
    };
  }, [cursorStyle, active, cursor]);

  return null;
});

export const formatDate = (date: Date | string) => {
  return new Date(date).toLocaleDateString("en-UK", {
    month: "short",
    year: "numeric",
    day: "numeric",
  });
};

export const formatTime = (seconds: number) => {
  const m = Math.floor(seconds / 60);
  const s = seconds % 60;
  return `${m}:${s.toString().padStart(2, "0")}`;
};

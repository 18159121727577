export const AnnotationType = {
  POINT: "POINT",
  LINE: "LINE",
  RADIUS_LINE: "RADIUS_LINE",
  PAINT_MESH: "PAINT_MESH",
  PAINT_IMAGE: "PAINT_IMAGE",
} as const;

export type AnnotationType =
  (typeof AnnotationType)[keyof typeof AnnotationType];

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { createNotification } from "@promaton/frontend-common";
import { AlertCircle, Plus, Restore } from "@promaton/icons";
import { FC, useState } from "react";

import { trpc } from "../hooks/trpc";
import { getSlugsInText } from "../utils/slugs";

export const AddAssignments: FC<{ projectId: string }> = ({ projectId }) => {
  const [open, setOpen] = useState(false);
  const project = trpc.project.get.useQuery({ id: projectId });
  const [slugListText, setSlugListText] = useState<string>("");
  const [slugs, setSlugs] = useState<string[]>([]);
  const isS3 = project.data?.dataSource === "S3";
  const syncAssignmentsMutation = trpc.project.addAssignments.useMutation();
  const utils = trpc.useContext();

  const addAssignments = async (slugs?: string[]) => {
    try {
      const result = await syncAssignmentsMutation.mutateAsync({
        id: projectId,
        slugs,
      });
      utils.assignment.list.invalidate();
      utils.project.get.invalidate({ id: projectId });

      createNotification({
        text: `Added ${result} new ${
          result === 1 ? "assignment" : "assignments"
        }`,
        color: "success",
      });
      setOpen(false);
    } catch (error) {
      createNotification({
        text: "Failed to add assignments",
        color: "error",
      });
    }
  };

  return (
    <>
      <Button startIcon={<Plus />} onClick={() => setOpen(true)}>
        Add Assignments
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          variant: "outlined",
          sx: { width: 700, maxWidth: "100%", border: "none" },
        }}
      >
        <DialogTitle>Add assignments</DialogTitle>
        <DialogContent
          sx={{
            gap: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {isS3 ? (
            <ListItemButton
              disabled={syncAssignmentsMutation.isLoading}
              onClick={() => addAssignments()}
            >
              <ListItemIcon>
                <Restore color="success" fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={"Sync with S3"}
                secondary={`Adds all missing assignments from ${project.data?.resourceLocation}`}
              ></ListItemText>
            </ListItemButton>
          ) : (
            <>
              <TextField
                disabled={syncAssignmentsMutation.isLoading}
                value={slugListText}
                onChange={(e) => {
                  setSlugListText(e.target.value);
                  const slugs = getSlugsInText(e.target.value);
                  setSlugs(slugs);
                }}
                placeholder={`Enter scan slugs separated by a space, new line or ",".`}
                fullWidth
                multiline
                rows={10}
              />
              <Button
                onClick={() => {
                  addAssignments(slugs);
                }}
                variant="contained"
                disabled={!slugs.length || syncAssignmentsMutation.isLoading}
              >
                Add {slugs.length} assignments
              </Button>
            </>
          )}

          <Divider flexItem />
          <ListItem disablePadding>
            <AlertCircle color="warning" sx={{ mr: 2 }} />
            <Typography variant="body2">
              Warning: newly added assignments will be made available regardless
              of rollout, to avoid side effects. If this is not intended, apply
              the project rollout again afterwards.
            </Typography>
          </ListItem>
        </DialogContent>
      </Dialog>
    </>
  );
};

import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  Slider,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useDialogs } from "@promaton/frontend-common";
import { CaretLeft, CircleFilledLarge, Download } from "@promaton/icons";
import { sentenceCase, snakeCase } from "change-case";
import { FC, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation } from "wouter";

import { AdminAssignmentTable } from "../../components/AdminAssignmentTable";
import { AdminSubmissionTable } from "../../components/AdminSubmissionTable";
import { AssignmentTypeName } from "../../components/AssignmentCard";
import { statusColor } from "../../components/StatusChip";
import { APIOutput, trpc } from "../../hooks/trpc";
import { formatDate } from "../../utils/dateFormatter";
import { createRouteLink, routes } from "../routes";

const TabType = {
  ASSIGNMENTS: "Assignments",
  SUBMISSIONS: "Submissions",
} as const;

type TabType = (typeof TabType)[keyof typeof TabType];

export const ProjectDetailPage: FC<{ id: string }> = ({ id }) => {
  const { control, handleSubmit, setValue } = useForm<{
    rollout: number;
  }>({
    defaultValues: {
      rollout: 0,
    },
  });
  const [tab, setTab] = useState<TabType>(TabType.ASSIGNMENTS);
  const project = trpc.project.get.useQuery({ id });
  const updateProject = trpc.project.update.useMutation();
  const exportSubmissions = trpc.submission.export.useMutation();

  useEffect(() => {
    setValue(
      "rollout",
      project.data?.rollout ?? project.data?._count.assignments ?? 0
    );
  }, [project.data]);

  const aggregatedSubmissions = trpc.submission.aggregateProject.useQuery({
    id,
  });
  const assignment = useMemo(() => {
    return project.data?.assignments[0];
  }, [project.data]);

  const [totalAssignments, totalSubmissions, totalReviews] = useMemo(() => {
    const maxSubmissions = project.data?.submissionsPerAssignment ?? 1;
    const totalAssignments = project.data?._count.assignments ?? 0;
    const totalReviews = project.data?.numberOfReviewsPerSubmission ?? 0;
    return [
      totalAssignments,
      totalAssignments * maxSubmissions,
      totalReviews * maxSubmissions * totalAssignments,
    ];
  }, [project.data]);

  const deleteProject = trpc.project.delete.useMutation();
  const utils = trpc.useContext();
  const [_, setLocation] = useLocation();

  return (
    <>
      {project.isLoading && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, right: 0, zIndex: 10 }}
        />
      )}
      <Container sx={{ marginY: 2 }} maxWidth={"xl"}>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Link href={routes.adminRoot}>
            <IconButton>
              <CaretLeft />
            </IconButton>
          </Link>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              mt: 3,
              mb: 3,
              flex: 1,
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordBreak: "break-word",
            }}
          >
            {project.data?.name}
          </Typography>
          <Link href={createRouteLink(routes.projectEdit, { id })}>
            <Button variant={"outlined"}>Edit</Button>
          </Link>
        </Stack>

        <Stack gap={2}>
          <ProgressChart
            data={aggregatedSubmissions.data}
            total={totalSubmissions}
          />
          <Grid container spacing={3} mt={1}>
            <Grid xs={6} md={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6">Overview</Typography>
                  <Table
                    size="small"
                    sx={{
                      ["& td"]: {
                        borderBottom: "none",
                        ["&:first-child"]: {
                          paddingLeft: 0,
                        },
                      },
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell width={150}>Project Type</TableCell>
                        <TableCell>
                          {assignment?.type &&
                            AssignmentTypeName[assignment.type]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150}>Project status</TableCell>
                        <TableCell>
                          {project.data?.isArchived ? "Archived" : "Available"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total assignments</TableCell>
                        <TableCell>{totalAssignments}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Expected submissions</TableCell>
                        <TableCell>{totalSubmissions}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Expected reviews</TableCell>
                        <TableCell>{totalReviews}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Access</TableCell>
                        <TableCell>
                          {project.data?.groups.length
                            ? project.data.groups.map((g) => g.name).join(", ")
                            : "All users"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Data Source</TableCell>
                        <TableCell>
                          {sentenceCase(project.data?.dataSource ?? "")}
                        </TableCell>
                      </TableRow>
                      {project.data?.resourceLocation && (
                        <TableRow>
                          <TableCell sx={{ verticalAlign: "top" }}>
                            Resource location
                          </TableCell>
                          <TableCell sx={{ wordBreak: "break-all" }}>
                            {project.data.resourceLocation}
                          </TableCell>
                        </TableRow>
                      )}
                      {project.data?.comparisonLocation && (
                        <TableRow>
                          <TableCell sx={{ verticalAlign: "top" }}>
                            Comparison location
                          </TableCell>
                          <TableCell sx={{ wordBreak: "break-all" }}>
                            {project.data.comparisonLocation}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={6} md={6}>
              <Stack gap={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6">Status</Typography>
                    <Table
                      size="small"
                      sx={{
                        ["& td"]: {
                          borderBottom: "none",
                          ["&:first-child"]: {
                            paddingLeft: 0,
                          },
                        },
                      }}
                    >
                      <TableBody>
                        {aggregatedSubmissions.data?.map((i) => (
                          <TableRow key={i.state}>
                            <TableCell>
                              <Stack
                                flexDirection="row"
                                alignItems="center"
                                gap={1}
                              >
                                <CircleFilledLarge
                                  color={statusColor[i.state]}
                                />
                                {sentenceCase(i.state)}{" "}
                              </Stack>
                            </TableCell>
                            <TableCell>{i._count.state}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6">Rollout</Typography>
                    <Typography variant="body2" mb={1}>
                      Sets number of assignments made available
                    </Typography>
                    <form
                      onSubmit={handleSubmit(async (d) => {
                        await updateProject.mutateAsync({
                          id,
                          rollout: d.rollout,
                        });
                        utils.assignment.invalidate();
                        utils.project.get.invalidate({ id });
                      })}
                    >
                      <Controller
                        name="rollout"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Stack
                            flexDirection={"row"}
                            alignItems="center"
                            gap={2}
                          >
                            <Slider
                              size="small"
                              min={0}
                              max={project.data?._count.assignments}
                              step={1}
                              {...field}
                            />
                            <Typography variant="body2">
                              {field.value}
                            </Typography>
                          </Stack>
                        )}
                      />
                      <Button
                        sx={{ marginTop: 1 }}
                        disabled={updateProject.isLoading}
                        type="submit"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </form>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
          </Grid>

          <Typography variant="h6" mt={2}>
            Danger Zone
          </Typography>
          <Stack alignItems={"flex-start"} gap={1}>
            <Typography variant="body2">
              {project.data?.isArchived
                ? "Unarchiving a project will make it visible to annotators and reviewers again."
                : "Archiving a project will hide it from annotators and reviewers, but will not delete any data."}
            </Typography>
            <Button
              disabled={project.isLoading || updateProject.isLoading}
              color="warning"
              variant="outlined"
              onClick={async () => {
                await updateProject.mutateAsync({
                  isArchived: !project.data?.isArchived,
                  id,
                });
                utils.project.invalidate();
              }}
            >
              {project.data?.isArchived
                ? "Make project available"
                : "Archive project"}
            </Button>
          </Stack>
          <Divider />
          <Stack alignItems={"flex-start"} gap={1}>
            <Typography variant="body2">
              Deleting a project will delete all related assignments,
              submissions and their data.
            </Typography>
            <Button
              disabled={deleteProject.isLoading}
              color="error"
              variant="outlined"
              onClick={() => {
                useDialogs.getState().setConfirmation({
                  title: `Are you sure you want to delete ${project.data?.name}?`,
                  description:
                    "All related assignments and submissions and their data will be deleted.",
                  onConfirm: async () => {
                    await deleteProject.mutateAsync({ id });
                    utils.project.invalidate();
                    utils.assignment.invalidate();
                    utils.submission.invalidate();
                    setLocation(routes.adminRoot);
                  },
                  onCancel: () => null,
                });
              }}
            >
              Delete project
            </Button>
          </Stack>

          <Stack sx={{ mt: 4 }} flexDirection="row" alignItems="center">
            <Tabs
              textColor="inherit"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: (t) => t.palette.text.primary,
                },
              }}
              sx={{ flex: 1 }}
              value={tab}
              onChange={(_, value) => setTab(value)}
            >
              <Tab
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginRight: 3,
                  fontSize: (t) => t.typography.h6.fontSize,
                }}
                label={TabType.ASSIGNMENTS}
                value={TabType.ASSIGNMENTS}
              />
              <Tab
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  fontSize: (t) => t.typography.h6.fontSize,
                }}
                label={TabType.SUBMISSIONS}
                value={TabType.SUBMISSIONS}
              />
            </Tabs>
            <Button
              startIcon={<Download />}
              disabled={exportSubmissions.isLoading}
              onClick={async () => {
                const res = await exportSubmissions.mutateAsync({
                  project: id,
                });
                console.log(res);
                const link = document.createElement("a");
                const a = new Uint8Array(res.data);
                link.href = URL.createObjectURL(new Blob([a]));
                const fileName = snakeCase(
                  `${project.data?.name} ${formatDate(new Date())}`
                );

                link.download = `${fileName}.xlsx`;
                link.click();
                link.remove();
              }}
            >
              Export to Excel
            </Button>
          </Stack>
          {tab === TabType.SUBMISSIONS && <AdminSubmissionTable id={id} />}
          {tab === TabType.ASSIGNMENTS && <AdminAssignmentTable id={id} />}
        </Stack>
      </Container>
    </>
  );
};

type ProgressData = APIOutput["submission"]["aggregateProject"];

const Part = styled("div")`
  height: 100%;
`;

const ProgressChart: FC<{ data?: ProgressData; total?: number }> = ({
  data = [],
  total = 100,
}) => {
  return (
    <Stack
      flexDirection="row"
      sx={{
        width: "100%",
        height: "0.5rem",
        borderRadius: 2,
        overflow: "hidden",
        background: (t) => t.palette.divider,
      }}
    >
      {data.map((category) => (
        <Tooltip
          title={`${sentenceCase(category.state)} (${category._count.state})`}
          key={category.state}
        >
          <Part
            sx={{
              background: (t) =>
                //@ts-ignore
                t.palette[statusColor[category.state] as any].main,
              width: `${(category._count.state * 100) / total}%`,
            }}
          />
        </Tooltip>
      ))}
    </Stack>
  );
};

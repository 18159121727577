import "survey-core/defaultV2.min.css";

import { CircularProgress, Stack, styled } from "@mui/material";
import surveyJson from "@promaton/annotator-server/src/forms/defaultSurvey.json";
import { useDialogs } from "@promaton/frontend-common";
import { FC, memo, useCallback, useMemo } from "react";
import { useUnmount } from "react-use";
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import { generateUUID } from "three/src/math/MathUtils";
import { useLocation } from "wouter";

import { trpc } from "../hooks/trpc";
import { Role, useRole } from "../hooks/useRole";
import { routes } from "../routes/routes";
import { useAppState } from "../stores/useAppState";

StylesManager.applyTheme("defaultV2");

export const FormFlow: FC<{
  id: string;
  isReview: boolean;
  isReadOnly: boolean;
}> = memo(({ id, isReview, isReadOnly }) => {
  const email = useAppState((s) => s.session?.email);
  const submission = trpc.submission.get.useQuery({ id });
  const update = trpc.submission.updateData.useMutation();
  const updateState = trpc.submission.updateState.useMutation();
  const [_, setLocation] = useLocation();
  const role = useRole();
  const utils = trpc.useContext();

  const canEdit = useMemo(() => {
    if (isReadOnly) return false;
    return role !== Role.ANNOTATOR || submission.data?.annotatorEmail === email;
  }, [role, email, submission, isReadOnly]);

  const model = useMemo(() => {
    if (!submission.data) return null;
    const model = new Model(
      submission.data.assignment.project.assignmentConfig || surveyJson
    );
    model.showCompletedPage = false;
    if (!canEdit) {
      model.getAllQuestions().forEach((q) => (q.readOnly = true));
    }
    return model;
  }, [submission.isFetched, canEdit]);

  const empty = useMemo(() => {
    if (!model) return;
    const empty: { [key: string]: any } = {};

    model
      .getAllQuestions()
      .filter((q) => q.getType() !== "image")
      .forEach((q) => {
        empty[q.name] = null;
      });

    return empty;
  }, [model]);

  const saveData = useCallback(
    async (model: Model) => {
      const { data } = model;
      if (!canEdit) return;
      return update.mutateAsync({ data: { ...empty, ...data }, id });
    },
    [role, id, canEdit]
  );

  const onComplete = useCallback(
    async (model: Model) => {
      try {
        await saveData(model);
        if (isReview) return;

        await updateState.mutateAsync({ id, state: "ANNOTATED" });
        useDialogs.getState().createNotification({
          color: "success",
          id: generateUUID(),
          text: "Completed assignment!",
        });
        setLocation(routes.root);
      } catch (error) {
        console.error(error);
        useDialogs.getState().createNotification({
          color: "error",
          id: generateUUID(),
          text: "Failed to complete assignment!",
        });
      }
      utils.submission.invalidate();
    },
    [role, id]
  );

  useUnmount(() => {
    utils.submission.invalidate();
  });

  return (
    <FormContainer>
      {submission.data ? (
        <Survey
          data={submission.data?.data || empty}
          model={model}
          onComplete={onComplete}
          onValueChanged={saveData}
        />
      ) : (
        <Stack>
          <CircularProgress />
        </Stack>
      )}
    </FormContainer>
  );
});

export default FormFlow;

export const FormContainer = styled("div")`
  ${({ theme }) => `
  overflow-y: auto;
  flex: 1;

  > * {
    --primary:${
      theme.palette.mode === "light"
        ? theme.palette.primary.light
        : theme.palette.primary.dark
    }};
    --background-dim:${theme.palette.background.paper};
    --background:${theme.palette.background.paper};
    --background-dim-light:${theme.palette.divider};;
    --primary-foreground:${theme.palette.primary.contrastText};
    --foreground:${theme.palette.text.primary};
    --foreground-disabled:${theme.palette.text.secondary};
    --sjs-font-editorfont-color: ${theme.palette.text.primary};
    --sjs-general-forecolor:${theme.palette.text.primary};

    .sd-container-modern {
      --sd-base-vertical-padding: 8px !important;
      --sd-base-padding: 4px !important;
    }

    .sd-page {
      padding-top: 0 !important;
    }

    .sd-question {
      box-shadow: none !important;
      background: none;
      padding-top: 0 !important;
      padding: ${theme.spacing(1)} !important;

      .sd-question__header {
        position: sticky;
        top: 0;
        z-index: ${theme.zIndex.fab};
        background: var(--background);
        padding: ${theme.spacing(2)} 0;
      }
    }

    .sd-imagepicker__text {
      white-space: normal;
      max-width: 150px;
    }

    .sd-text {
      border: 1px solid ${theme.palette.divider};
    }

    .sd-radio--disabled *  {
      *:after {
        background-color: var(--primary) !important;
      }

    }

    .sd-comment, .sd-input {
      background: transparent !important;
    }

    .sd-imagepicker__item--checked {
    .sd-imagepicker__check-decorator {
      width: 1.5rem;
      height: 1.5rem;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid var(--primary);
      padding: 0;
      place-items: center;

        display: grid !important;

        svg {
          height: 1rem;
          width: 1rem;
        }
      }
    }

    .sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
      fill: var(--primary);
    }

    .sd-element__title--disabled {
      opacity: 1.0 !important;
    }
  }
`}
`;

import { useMemo } from "react";
import { shallow } from "zustand/shallow";

import { useAppState } from "../stores/useAppState";

export const Role = {
  ADMIN: "admin",
  REVIEWER: "reviewer",
  ANNOTATOR: "annotator",
} as const;

export type Role = (typeof Role)[keyof typeof Role];

export const useRole = (): Role => {
  const groups = useAppState((s) => s.session?.["cognito:groups"], shallow);

  const role = useMemo(() => {
    if (groups?.includes(Role.ADMIN)) return Role.ADMIN;
    if (groups?.includes(Role.REVIEWER)) return Role.REVIEWER;
    return Role.ANNOTATOR;
  }, [groups]);
  return role;
};
